import React from "react";
import {Avatar, Spin} from "antd";
import {MessageRole, IMessage} from "../chatbot_types";

const StandardMessage: React.FC<IMessage> = (msg: IMessage) => {
    const isUser = msg.role === MessageRole.User;
    return (
        <div className={`flex items-start mb-4 ${isUser ? 'flex-row' : 'flex-row-reverse'}`}>
            <Avatar
                icon={<img src={isUser ? '/graphics/maple.png' : '/graphics/robot.png'} alt="Avatar" />}
                className={`${isUser ? 'mr-2' : 'ml-2'} 'bg-transparent'`}
            />
            <div
                className={`rounded-lg p-3 max-w-[70%] border ${isUser ? 'bg-white border-gray-500 text-black' : 'bg-slate-100 text-black'}`}>
                {msg.isLoading ? <Spin /> : <span>{msg.text}</span>}
                <span className="flex justify-end text-xs mt-1 opacity-50">
                    {msg.date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                </span>
            </div>
        </div>
    );
};

export default StandardMessage;