import React, {createContext, useContext, useState} from 'react';

interface ContextProviderProps {
    currentScreen: string;
    setCurrentScreen: React.Dispatch<React.SetStateAction<string>>
}

const AppContext = createContext<ContextProviderProps | undefined>(undefined);

const ContextProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [currentScreen, setCurrentScreen] = useState('home');

    return (
        <AppContext.Provider value={{currentScreen, setCurrentScreen}}>
            {children}
        </AppContext.Provider>
    );
};

export function useAppContext(): ContextProviderProps {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('useAppContext must be used within a ScreenProvider');
    }
    return context;
}

export default ContextProvider;