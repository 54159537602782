import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './components/app';
import {fetchClientTokens} from "./core/core";
import {initMixpanel, mixpanelIdentify} from "./analytics/mixpanel/mixpanel";
import {initHotjar} from "./analytics/hotjar";

const container = document.getElementById('root');
const root = createRoot(container!);

function main() {
    fetchClientTokens()
        .then((tokens) => {
            initMixpanel(tokens?.mixpanelToken || '');
            initHotjar(tokens?.hotjarSiteId || 0);
        })
        .then(() => {
            mixpanelIdentify();
        })
        .then(() => {
            root.render(<App/>);
        });
}

main();
