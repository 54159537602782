export interface MixpanelEvent {
    name: string,
    properties?: Record<string, any>
}


export enum MixpanelEventName {
    PAGE_LOADED_HOME = 'Page loaded - home',
    PAGE_LOADED_MAIN = 'Page loaded - main',
    PAGE_LOADED_UNSUPPORTED_DEVICE = 'Page loaded - unsupported device',
    BUTTON_CLICKED_SEARCH = 'Button clicked - search',
    BUTTON_CLICKED_LOGO_HOME = 'Button clicked - logo home',
    BUTTON_CLICKED_JOURNEY_SUGGESTION = 'Button clicked - journey suggestion',
    FIRST_TABLE_SHOWN = 'First table shown',
    TABLE_UPDATED = 'Table updated',
    CHAT_RESPONSE_BOT = 'Chat response - bot',
    CHAT_RESPONSE_USER = 'Chat response - user'
}


export const mixpanelEvents: Record<string, MixpanelEvent> = {
    [MixpanelEventName.PAGE_LOADED_HOME]: {
        name: MixpanelEventName.PAGE_LOADED_HOME
    },
    [MixpanelEventName.PAGE_LOADED_MAIN]: {
        name: MixpanelEventName.PAGE_LOADED_MAIN
    },
    [MixpanelEventName.PAGE_LOADED_UNSUPPORTED_DEVICE]: {
        name: MixpanelEventName.PAGE_LOADED_UNSUPPORTED_DEVICE
    },
    [MixpanelEventName.BUTTON_CLICKED_SEARCH]: {
        name: MixpanelEventName.BUTTON_CLICKED_SEARCH
    },
    [MixpanelEventName.BUTTON_CLICKED_LOGO_HOME]: {
        name: MixpanelEventName.BUTTON_CLICKED_LOGO_HOME
    },
    [MixpanelEventName.BUTTON_CLICKED_JOURNEY_SUGGESTION]: {
        name: MixpanelEventName.BUTTON_CLICKED_JOURNEY_SUGGESTION,
        properties: {
            location: 0,
            text: ''
        }
    },
    [MixpanelEventName.FIRST_TABLE_SHOWN]: {
        name: MixpanelEventName.FIRST_TABLE_SHOWN,
        properties: {
            number_of_columns: 0,
            number_of_rows: 0,
            $duration: 0
        }
    },
    [MixpanelEventName.TABLE_UPDATED]: {
        name: MixpanelEventName.TABLE_UPDATED,
        properties: {
            table_update_counter: 0,
            table_title: '',
            $duration: 0
        }
    },
    [MixpanelEventName.CHAT_RESPONSE_BOT]: {
        name: MixpanelEventName.CHAT_RESPONSE_BOT,
        properties: {
            response: '',
            response_number: 0,
            type: '',
            $duration: 0
        }
    },
    [MixpanelEventName.CHAT_RESPONSE_USER]: {
        name: MixpanelEventName.CHAT_RESPONSE_USER,
        properties: {
            response: '',
            response_number: 0,
            type: '',
            last_message: '',
            follow_up: false,
            $duration: 0
        }
    }
}