import React, {useState} from 'react';
import Table from '../table/table';
import styles from '../../styles/screen/split_screen.module.css';
import '../../styles/styles.css';

import {ToolData} from "../../core/core";
import ChatComponent from "../chatbot/chat";
import LoaderAnimation from "../loader_animation";
import Screen from "./screen";
import EmptyData from "../empty_data";
import {MixpanelEvent, MixpanelEventName, mixpanelEvents} from "../../analytics/mixpanel/mixpanel_event";
import {TABLE_COLUMNS_COUNT} from "../../config/config";

interface SplitScreenProps {
    initialPrompt: string

    onTransition(): void
}

enum InfoState {
    EMPTY,
    LOADING,
    LOADED
}

const SplitScreen: React.FC<SplitScreenProps> = ({initialPrompt}) => {
    const [headline, setHeadline] = useState<string>('');
    const [tools, setTools] = React.useState<ToolData[]>([]);
    const [infoState, setInfoState] = React.useState<InfoState>(InfoState.EMPTY);

    const handleToolsUpdate = (count: number, _tools: ToolData[]) => {
        let event: MixpanelEvent;
        if (count === 1) {
            event = mixpanelEvents[MixpanelEventName.FIRST_TABLE_SHOWN];
            event.properties = {
                number_of_columns: _tools.length,
                number_of_rows: TABLE_COLUMNS_COUNT,
            }
        } else {
            event = mixpanelEvents[MixpanelEventName.TABLE_UPDATED];
            event.properties = {
                table_update_counter: count,
                table_title: headline,
            }
        }
        event.properties!.$duration = Date.now() - event.properties!.$duration;
        setTools(_tools.slice(0, 3));
        setInfoState(InfoState.LOADED);
    };

    const handleHeadlineUpdate = (_headline: string) => {
        setHeadline(_headline);
    };

    const handleFetchingTools = (count: number) => {
        const event = count === 1 ? mixpanelEvents[MixpanelEventName.FIRST_TABLE_SHOWN] : mixpanelEvents[MixpanelEventName.TABLE_UPDATED];
        event.properties!.$duration = Date.now();

        if (infoState !== InfoState.LOADED) {
            setInfoState(InfoState.LOADING);
        }
    }

    return (
        <Screen bottomSeperator={true} logoSize={100}>
            <div className={styles.content}>
                <div className={styles.main}>
                    <div className={styles.chatContainer}>
                        <ChatComponent initialPrompt={initialPrompt}
                                       onHeadlineUpdate={handleHeadlineUpdate}
                                       onFetchingTools={handleFetchingTools}
                                       onToolsUpdate={handleToolsUpdate}/>
                    </div>
                    <div className={styles.separator}/>
                    <div className={infoState === InfoState.LOADED ? styles.tableContainer : styles.loaderContainer}>
                        {infoState === InfoState.EMPTY ? <EmptyData/> : (
                            infoState === InfoState.LOADING ? <LoaderAnimation width="700px" height="500px"/> :
                                <Table headline={headline} tools={tools}/>)}
                    </div>
                </div>
            </div>
        </Screen>
    );
};

export default SplitScreen;