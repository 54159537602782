import {collectUserData} from "../logging/user_data_collector";
import {sessionId} from "../auth/auth";

export async function request(req: string) {
    const req_with_lr = req.replace(/\r\n|\r|\n/g, '<br>').replace(/\?/g, '');
    const encodedRequest = encodeURIComponent(req_with_lr);
    const gResponse = await fetch('/' + encodedRequest, {
        headers: {
            'X-User-Data': JSON.stringify(collectUserData()),
            "Accept": "application-json"
        }
    });
    return gResponse?.json();
}

export interface ServerAPIResponse {
    sessionId: string
    response: any
}

export async function api(req: string, ...args: string[]): Promise<ServerAPIResponse> {
    /* Add session id to the request. */
    
    args.unshift(sessionId());

    const encodedArgs = args.map(arg => encodeURIComponent(arg));
    const path = ['api', req, ...encodedArgs].join('/');
    return (await request(path)) as ServerAPIResponse;
}