import React from 'react';
import {Layout, Button, Image} from 'antd';
import classNames from "classnames";

const {Header} = Layout;

interface HeaderComponentProps {
    onLogin?: () => void;
    onSignup?: () => void;
    onLogoClick?: () => void;
    bottomSeparator?: boolean;
    logoSize: number;
}

const HeaderComponent: React.FC<HeaderComponentProps> = ({onLogin, onSignup, onLogoClick, bottomSeparator, logoSize}) => {
    const headerClasses = classNames(
        "top-0 left-0 right-0 z-50 bg-white px-4 py-2 h-24",
        {"border-b": bottomSeparator !== undefined && bottomSeparator}
    );

    return (
        <Header className={headerClasses}>
            <div className="mx-auto flex justify-between items-center h-full">
                <div onClick={onLogoClick} className="cursor-pointer mt-6">
                    <Image src="/graphics/logo.svg" alt="Nolos Logo" width={logoSize} height={logoSize} preview={false} />
                </div>
                <div className="space-x-4">
                    <Button
                        type="primary"
                        shape="round"
                        className="border border-gray-300 text-gray-600 hover:bg-gray-100"
                        onClick={onLogin}
                        hidden={true}
                    >
                        Log in
                    </Button>
                    <Button
                        shape="round"
                        className="bg-black border-black hover:bg-gray-800"
                        onClick={onSignup}
                        hidden={true}
                    >
                        Sign up
                    </Button>
                </div>
            </div>
        </Header>
    );
};

export default HeaderComponent;