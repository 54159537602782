import React from 'react';
import {Empty} from "antd";
import {emptyStateDescription} from "../config/config";

const EmptyData: React.FC = () => {
    return (
        <Empty description={emptyStateDescription}/>
    )
};

export default EmptyData;