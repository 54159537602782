import React, {useEffect, useRef, useState} from 'react';
import {Table, Progress, Typography, Tooltip} from 'antd';
import {CheckCircleFilled, CloseCircleFilled, DollarOutlined} from '@ant-design/icons';
import {ToolData} from "../../core/core";
import Logo from "../logo";
import SymbolList from "./symbol_list";
import {TableColumns} from "../../config/config";

const {Title} = Typography;

interface ComparisonTableProps {
    headline: string;
    tools: ToolData[];
}

type DataType = {
    [key: string]: React.ReactNode;
    feature: string;
};

const ComparisonTable: React.FC<ComparisonTableProps> = ({headline, tools}) => {
    const [tableHeight, setTableHeight] = useState<number | string>('auto');
    const containerRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        const updateTableHeight = () => {
            const height = containerRef.current?.clientHeight;
            setTableHeight(height || 'auto');
        };

        updateTableHeight();
        window.addEventListener('resize', updateTableHeight);

        return () => window.removeEventListener('resize', updateTableHeight);
    });

    const renderEaseOfUse = (value: number) => {
        const color = value > 7 ? '#52c41a' : value > 5 ? '#faad14' : '#f5222d';
        return (
            <Progress
                type="circle"
                percent={value * 10}
                format={() => `${value}`}
                width={50}
                strokeColor={color}
            />
        );
    };

    const renderPricing = (value: number) => (
        <span className="text-lg">
      {[...Array(value)].map((_, i) => (
          <DollarOutlined key={`dollar-${value}-${i}`} className="text-green-600"/>
      ))}
    </span>
    );

    const renderDomain = (domain: string) => {
        const href = domain.startsWith('http://') || domain.startsWith('https://') ? domain : `https://${domain}`;
        return (<a href={href} className="cursor-pointer font-medium" target="_blank"
                   rel="noopener noreferrer">{domain}</a>)
    }


    const columns = [
        {
            title: '',
            dataIndex: 'feature',
            key: 'feature',
            width: 150,
            fixed: 'left' as const,
        },
        ...tools.map((tool) => ({
            title: () => (
                <Logo key={`logo-${tool.name}`} src={tool.logo} alt={tool.name}/>
            ),
            dataIndex: tool.name,
            key: tool.name,
            align: 'center' as const,
            width: 200,
        })),
    ];

    const data: DataType[] = [
        {
            feature: TableColumns.DOMAIN,
            ...Object.fromEntries(tools.map((tool) => [tool.name, renderDomain(tool.domain)])),
        },
        {
            feature: TableColumns.EASE_OF_USE,
            ...Object.fromEntries(tools.map((tool) => [tool.name, renderEaseOfUse(tool.easeOfUse)])),
        },
        {
            feature: TableColumns.PRICING,
            ...Object.fromEntries(tools.map((tool) => [tool.name, renderPricing(tool.pricing)])),
        },
        {
            feature: TableColumns.ORGANIZATION_SIZE,
            ...Object.fromEntries(tools.map((tool) => [tool.name, tool.organizationSize])),
        },
        {
            feature: TableColumns.LOVE,
            ...Object.fromEntries(tools.map((tool) => [
                tool.name,
                <Tooltip key={`love-${tool.name}`} title={tool.love} className="flex justify-start content-start">
                    <SymbolList items={tool.love.split(', ')} symbol={<CheckCircleFilled className="text-green-600"/>}/>
                </Tooltip>
            ])),
        },
        {
            feature: TableColumns.HATE,
            ...Object.fromEntries(tools.map((tool) => [
                tool.name,
                <Tooltip key={`hate-${tool.name}`} title={tool.hate}>
                    <SymbolList items={tool.hate.split(', ')} symbol={<CloseCircleFilled className="text-red-600"/>}/>
                </Tooltip>
            ])),
        },
        {
            feature: TableColumns.SUPPORT_LEVEL,
            ...Object.fromEntries(tools.map((tool) => [tool.name, tool.supportLevel])),
        },
        {
            feature: TableColumns.EASE_OF_INTEGRATION,
            ...Object.fromEntries(tools.map((tool) => [tool.name, tool.easeOfIntegration])),
        },
    ];

    return (
        <div ref={containerRef} className="max-w-6xl mx-auto p-4 flex-col flex" style={{height: '70%'}}>
            <Title level={2} className="text-center mb-6">
                {headline}
            </Title>
            <Table<DataType>
                columns={columns}
                dataSource={data}
                pagination={false}
                style={{height: tableHeight}}
                scroll={{x: 'max-content', y: tableHeight}}
                bordered
                className="shadow-lg"

            />
        </div>
    );
};

export default ComparisonTable;