import React, {useEffect, useRef, useState} from 'react';
import {Button, Input} from 'antd';
import {SendOutlined} from '@ant-design/icons';
import {IMessage, Message, MessageRole, MessageType, QuestionMessageType} from "./chatbot_types";
import {toolsFoundMessageText} from "../../config/config";
import {
    firstPrompt,
    FirstPromptResponse,
    FollowupQuestionResponse,
    getTools,
    MixedResponse,
    NPrompt,
    NPromptResponse,
    ResponseType,
    ToolData,
    ToolNamesResponse
} from "../../core/core";
import StandardMessage from "./message/standard_message"
import QuestionMessage from "./message/question_message";
import {mixpanelTrack} from "../../analytics/mixpanel/mixpanel";
import {MixpanelEventName, mixpanelEvents} from "../../analytics/mixpanel/mixpanel_event";


interface ChatProps {
    initialPrompt: string
    onHeadlineUpdate: (headline: string) => void;
    onFetchingTools: (count: number) => void;
    onToolsUpdate: (count: number, tools: ToolData[]) => void;
}

const ChatComponent: React.FC<ChatProps> = ({initialPrompt, onHeadlineUpdate, onFetchingTools, onToolsUpdate}) => {
    const [messages, setMessages] = useState<IMessage[]>([]);
    const [inputText, setInputText] = useState<string>('');
    const [lastMessage, setlastMessage] = useState<IMessage>(Message.EMPTY);
    const [lastUserMessageTime, setLastUserMessageTime] = useState<number>(0);
    const [lastBotMessageTime, setLastBotMessageTime] = useState<number>(0);
    const [toolsFetchingCounter, setToolsFetchingCounter] = useState<number>(0);
    const messagesEndRef = useRef<HTMLDivElement>(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({behavior: "smooth"});
    };

    const handleSendMessage = async () => {
        if (inputText.trim() && lastMessage) {
            setLastUserMessageTime(Date.now());
            mixpanelEvents[MixpanelEventName.CHAT_RESPONSE_USER].properties = {
                response: inputText,
                response_number: lastMessage.id,
                last_message: lastMessage.text,
                followup: lastMessage.role === MessageRole.User,
                $duration: Date.now() - lastBotMessageTime
            };
            mixpanelTrack(MixpanelEventName.CHAT_RESPONSE_USER);
            void handlePrompt(inputText, MessageType.OPEN, NPrompt(lastMessage.text, inputText));
        }
    }

    const handlePrompt = async (prompt: string, msgType: MessageType, response: Promise<FirstPromptResponse> | Promise<NPromptResponse>) => {
        setInputText('');
        const loadingMessageId = addUserMsgAndBotLoading(prompt, msgType);
        const promptResponse: FirstPromptResponse | NPromptResponse = await response;
        setLastBotMessageTime(Date.now());
        const msgs = handlePromptResponse(promptResponse);
        setMessages(prev => [...prev.filter(msg => msg.id !== loadingMessageId), ...msgs]);
        return promptResponse
    }

    const handleFirstPrompt = async (prompt: string) => {
        const promptResponse = handlePrompt(prompt, MessageType.OPEN, firstPrompt(prompt));
        onHeadlineUpdate((await promptResponse as FirstPromptResponse).headline);
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        setLastUserMessageTime(Date.now());
        void handleFirstPrompt(initialPrompt);
    }, []);

    function handlePromptResponse(promptResponse: FirstPromptResponse | NPromptResponse): IMessage[] {
        let messages: IMessage[] = [];

        function handleToolNamesResponse(promptResponse: ToolNamesResponse) {
            const toolNames = (JSON.parse(promptResponse as unknown as string) as ToolNamesResponse).toolNames;
            const message = fetchTools(toolNames);
            if (message) {
                pushMessages(message);
            }
        }

        function handleFollowupQuestionResponse(promptResponse: FollowupQuestionResponse) {
            const response = JSON.parse(promptResponse as unknown as string) as FollowupQuestionResponse;
            const followup = response as FollowupQuestionResponse;
            messages.push(createBotMessage(followup.preQuestionsMsg, MessageType.INFO));
            messages.push(createBotQuestionMessage(followup.question, followup.options));
        }

        switch (promptResponse.type) {
            case ResponseType.ToolNamesResponse:
                handleToolNamesResponse(promptResponse.response as ToolNamesResponse);
                break;
            case ResponseType.FollowupQuestionResponse:
                handleFollowupQuestionResponse(promptResponse.response as FollowupQuestionResponse);
                break;
            case ResponseType.MixedResponse:
                handleToolNamesResponse((promptResponse.response as MixedResponse).toolNamesResponse);
                handleFollowupQuestionResponse((promptResponse.response as MixedResponse).followupQuestionResponse);
                break;
        }

        /* Update time once. */
        const endTime = Date.now();
        for (const msg of messages) {
            mixpanelEvents[MixpanelEventName.CHAT_RESPONSE_BOT].properties = {
                response: msg.text,
                response_number: msg.id,
                type: msg.type.toString(),
                $duration: endTime - lastUserMessageTime
            };

            mixpanelTrack(MixpanelEventName.CHAT_RESPONSE_BOT);
        }

        return messages;
    }

    function fetchTools(names: string[]): IMessage | undefined {
        /* Search for tools. */
        if (!names || names.length === 0) {
            return Message.EMPTY;
        }

        setToolsFetchingCounter(toolsFetchingCounter + 1);
        onFetchingTools(toolsFetchingCounter);
        getTools(names).then(names => onToolsUpdate(toolsFetchingCounter, names));

        return Message.create(toolsFoundMessageText, MessageType.TOOLS_FOUND, MessageRole.Bot);
    }

    function createBotMessage(msg: string | null, type: MessageType): IMessage {
        return Message.create(msg ? msg : '', type, MessageRole.Bot);
    }

    function createUserMessage(msg: string | null, type: MessageType): IMessage {
        return Message.create(msg ? msg : '', type, MessageRole.User);
    }

    function createBotQuestionMessage(question: string | null, options: string[]) {
        return Message.createQuestion(question ? question : '', question ? question : '', options);
    }

    function addUserMsgAndBotLoading(inputText: string, type: MessageType): number {
        const loadingMessage = Message.LOADING;
        pushMessages(createUserMessage(inputText, type), loadingMessage);
        return loadingMessage.id;
    }

    function pushMessages(...messages: IMessage[]) {
        const lastMessage = messages.length > 0 ? messages[messages.length - 1] : null;
        setlastMessage(lastMessage ? lastMessage : Message.EMPTY);
        setMessages(prev => [...prev, ...messages]);
    }

    async function questionOptionClicked(question: string, answer: string) {
        void handlePrompt(answer, MessageType.CHIP_SELECT, NPrompt(question, answer));
    }

    return (
        <div className="flex flex-col max-w-2xl mx-auto h-full bg-white border border-gray-300 rounded-lg shadow-lg overflow-hidden">
            <div className="flex-grow overflow-y-auto p-4 space-y-4">
                {messages.map((message, index) => (
                    'options' in message ? (
                            <QuestionMessage key={index} msg={message as QuestionMessageType}
                                             onOptionClick={questionOptionClicked}/>) :
                        <StandardMessage key={index} {...message} />))
                }
                <div ref={messagesEndRef}/>
            </div>
            <div className="p-4 border-t">
                <div className="flex space-x-2">
                    <Input
                        value={inputText}
                        onChange={(e) => setInputText(e.target.value)}
                        onPressEnter={handleSendMessage}
                        placeholder="Type a message..."
                        className="flex-1"
                    />
                    <Button
                        type="primary"
                        icon={<SendOutlined/>}
                        onClick={handleSendMessage}
                        className="bg-blue-500 hover:bg-blue-600"
                    >
                        Send
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ChatComponent;