import React, {useEffect} from 'react';
import {Input, Card, Typography, Row, Col} from 'antd';
import {PaperClipOutlined} from '@ant-design/icons';
import '../../styles/styles.css';
import {exampleQueries} from "../../config/config";
import Screen from "./screen";
import {mixpanelTrack} from "../../analytics/mixpanel/mixpanel";
import {MixpanelEventName, mixpanelEvents} from "../../analytics/mixpanel/mixpanel_event";

const {Title, Paragraph} = Typography;

interface HomeScreenProps {
    onPromptSend(prompt: string): void;
}

const HomeScreen: React.FC<HomeScreenProps> = ({onPromptSend}) => {

    useEffect(() => {
        mixpanelTrack(MixpanelEventName.PAGE_LOADED_HOME);
    }, []);

    const handleButtonClick = (index: number, prompt: string) => {
        mixpanelEvents[MixpanelEventName.BUTTON_CLICKED_JOURNEY_SUGGESTION].properties!.location = index;
        mixpanelEvents[MixpanelEventName.BUTTON_CLICKED_JOURNEY_SUGGESTION].properties!.text = prompt;
        mixpanelTrack(MixpanelEventName.BUTTON_CLICKED_JOURNEY_SUGGESTION);
        onPromptSend(prompt);
    }

    const handleInputSearch = (prompt: string) => {
        onPromptSend(prompt);
    }

    return (
        <Screen bottomSeperator={false} logoSize={100}>
            <div className="content-container">
                <Title level={2} className="text-center mb-4">AI-Powered Software Matchmaker</Title>
                <Paragraph className="text-center text-gray-600 mb-8">
                    Navigate the digital landscape with a personal AI guide. Tailored recommendations for your unique
                    needs.
                </Paragraph>

                <Input.Search
                    placeholder="Enter product name, software category, service name"
                    enterButton="Search"
                    size="large"
                    prefix={<PaperClipOutlined/>}
                    className="mb-4"
                    onSearch={handleInputSearch}
                />

                <Paragraph className="text-sm text-gray-500 mb-4">
                    Let us know what you are trying to do and we will find the best solution for you
                </Paragraph>

                <Row gutter={[16, 16]}>
                    {exampleQueries.map((query, index) => (
                        <Col xs={24} sm={12} md={8} key={index}>
                            <Card
                                className="h-full bg-gray-100 hover:bg-gray-200 cursor-pointer transition flex justify-center"
                                onClick={() => handleButtonClick(index, query)}>
                                <div className="h-full flex items-center justify-center text-center">
                                    {query}
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </Screen>
    );
};

export default HomeScreen;