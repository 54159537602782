import React from 'react';
import {Layout} from 'antd';
import '../../styles/styles.css';
import '../../styles/screen/screen.module.css';
import HeaderComponent from "./../header";
import {useAppContext} from "../context_provider";
import {mixpanelTrack} from "../../analytics/mixpanel/mixpanel";
import {MixpanelEventName} from "../../analytics/mixpanel/mixpanel_event";

const {Content} = Layout;

interface ScreenProps {
    bottomSeperator: boolean
    logoSize: number
    children: React.ReactNode;
}

const Screen: React.FC<ScreenProps> = ({bottomSeperator, logoSize, children}) => {

    const {setCurrentScreen} = useAppContext();

    function onLogoClick() {
        mixpanelTrack(MixpanelEventName.BUTTON_CLICKED_LOGO_HOME);
        setCurrentScreen('home');
    }

    return (
        <Layout className="min-h-screen bg-white h-full">
            <HeaderComponent onLogin={() => {
            }} onSignup={() => {
            }} onLogoClick={onLogoClick} bottomSeparator={bottomSeperator} logoSize={logoSize}/>
            <Content className="w-full h-full flex">
                {children}
            </Content>
        </Layout>
    );
};

export default Screen;