import React from "react";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import {Typography} from 'antd';

const {Title} = Typography;

interface LoaderAnimationProps {
    width?: string | number;
    height?: string | number;
    withTitle?: boolean;
}

const LoaderAnimation: React.FC<LoaderAnimationProps> = ({width, height, withTitle=true}) => {
    return (
        <div className="flex items-center justify-center" style={{width: width, height: height}}>
            <div className="flex flex-col items-center">
                <DotLottieReact
                    src="/animations/loading_dog.json"
                    loop
                    autoplay
                    className="mt-8"
                />
                {withTitle ? <Title level={1}>Loading...</Title> : <> </>}
            </div>
        </div>
    );
}

export default LoaderAnimation;
