import React from "react";

interface LogoProps {
    src: string;
    alt: string;
    width?: number;
}

const Logo: React.FC<LogoProps> = ({src, alt, width = 16}) => {
    const [aspectRatio, setAspectRatio] = React.useState(1);

    const handleImageLoad = (event: any) => {
        setAspectRatio(event.target.naturalWidth / event.target.naturalHeight);
    };

    return (
        <div className={`relative w-${width}`} style={{}}>
            <img
                src={src}
                alt={alt}
                onLoad={handleImageLoad}
                className="mx-auto mb-2 w-full h-full object-cover"
            />
        </div>
    );
}


export default Logo;