import React from 'react';
import '../styles/styles.css';

import {isMobile} from 'react-device-detect';

import ScreenTransition from "./screen_transition";
import ContextProvider from "./context_provider";
import UnsupportedDevice from "./unsupported_device";

const App: React.FC = () => {
    if (isMobile) {
        return (
            <UnsupportedDevice/>
        )
    }

    return (
        <ContextProvider>
            <ScreenTransition/>
        </ContextProvider>
    );
};

export default App;