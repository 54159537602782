import React, {ReactElement} from 'react';
import {List} from "antd";

interface SymbolListProps {
    items: any[]
    symbol: ReactElement
}

const SymbolList: React.FC<SymbolListProps> = (({items, symbol}) => {
    return (<List dataSource={items} renderItem={(item) => (
        <List.Item className="p-0">
            <div className="w-full flex">
                <div className="pr-2">
                    {React.cloneElement(symbol)}
                </div>
                <div className="text-left">
                    {item}
                </div>
            </div>
        </List.Item>
    )}/>)
});

export default SymbolList;