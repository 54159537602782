import mixpanel from 'mixpanel-browser';
import {getUserId} from "../../auth/auth";
import {MixpanelEvent, MixpanelEventName, mixpanelEvents} from "./mixpanel_event";

export function initMixpanel(token: string): void {
    try {
        mixpanel.init(token, {
            debug: process.env.NODE_ENV !== 'production',
            track_pageview: true,
            persistence: 'localStorage',
        });
    } catch (error) {
        console.error('Error in initializing Mixpanel:', error);
    }
}

export function mixpanelIdentify(): void {
    mixpanel.identify(getUserId());
}
export function updateUser(properties: Record<string, any>) {
    mixpanel.people.set(getUserId(), properties)
}

export function getUserProperties(): Record<string, any> {
    return mixpanel.get_distinct_id()
}

export function mixpanelTrack(eventName: MixpanelEventName) {
    const event: MixpanelEvent = mixpanelEvents[eventName];
    mixpanel.track(event.name.toString(), event?.properties)
}

export {mixpanel}