import React from "react";
import {Avatar, Button, Spin} from "antd";
import {MessageRole, QuestionMessageType} from "../chatbot_types";

export interface QuestionMessageProps {
    msg: QuestionMessageType;
    onOptionClick: (question: string, answer: string) => void
}

const QuestionMessage: React.FC<QuestionMessageProps> = ({msg, onOptionClick}) => {
    const isUser = msg.role === MessageRole.User;

    return (
        <div className={`flex items-start mb-4 ${isUser ? 'flex-row' : 'flex-row-reverse'}`}>
            <Avatar
                icon={<img src={isUser ? '/graphics/soussan.jpeg' : '/graphics/robot.png'} alt="Avatar"/>}
                className={`${isUser ? 'mr-2' : 'ml-2'} 'bg-transparent'`}
            />
            <div
                className={`rounded-lg p-3 max-w-[70%] border ${isUser ? 'bg-white border-gray-500 text-black' : 'bg-slate-100 text-black'}`}>
                {msg.isLoading ? <Spin/> : (
                    <>
                        <span>{msg.text}</span>
                        <div className="mt-2 flex flex-wrap gap-2">
                            {msg.options.map((option: string, index: number) => (
                                <Button key={index} onClick={() => onOptionClick(msg.question, option)}
                                        className="h-auto whitespace-normal">
                                    {option}
                                </Button>
                            ))}
                        </div>
                    </>
                )}
                <span className="flex justify-end text-xs mt-1 opacity-50">
                    {msg.date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}
                </span>
            </div>
        </div>
    );
};

export default QuestionMessage;