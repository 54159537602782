import React from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import HomeScreen from './screen/home_screen';
import SplitScreen from './screen/split_screen';
import {useAppContext} from "./context_provider";
import {mixpanelTrack} from "../analytics/mixpanel/mixpanel";
import {MixpanelEventName} from "../analytics/mixpanel/mixpanel_event";

const ScreenTransition: React.FC = () => {
    const {currentScreen, setCurrentScreen} = useAppContext();
    const [initialPrompt, setInitialPrompt] = React.useState('');

    const screenVariants = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
    };

    const onPromptSend = (prompt: string): void => {
        setInitialPrompt(prompt);
        mixpanelTrack(MixpanelEventName.PAGE_LOADED_MAIN);
        setCurrentScreen('split');
    }

    return (
        <div className="fixed inset-0 z-50">
            <AnimatePresence mode="wait">
                {currentScreen === 'home' ? (
                    <motion.div
                        key="home"
                        variants={screenVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        transition={{ duration: 0.5 }}
                        className="absolute w-full h-full"
                    >
                        <HomeScreen onPromptSend={onPromptSend} />
                    </motion.div>
                ) : (
                    <motion.div
                        key="split"
                        variants={screenVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        transition={{ duration: 0.5 }}
                        className="absolute w-full h-full"
                    >
                        <SplitScreen initialPrompt={initialPrompt} onTransition={() => {}} />
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default ScreenTransition;