import React, {useEffect} from 'react';
import {Result, Button} from 'antd';
import {LaptopOutlined} from '@ant-design/icons';
import LoaderAnimation from "./loader_animation";
import {MixpanelEventName} from "../analytics/mixpanel/mixpanel_event";
import {mixpanelTrack} from "../analytics/mixpanel/mixpanel";

const UnsupportedDevice: React.FC = () => {

    useEffect(() => {
        mixpanelTrack(MixpanelEventName.PAGE_LOADED_UNSUPPORTED_DEVICE);
    }, []);

    return (
        <div className="min-h-screen bg-gradient-to-b from-blue-100 to-blue-200 flex items-center justify-center p-4">
            <Result
                icon={<LoaderAnimation withTitle={false}/>}
                title={
                    <h1 className="text-3xl md:text-4xl font-bold text-gray-800">
                        Desktop only
                    </h1>
                }
                subTitle={
                    <p className="text-lg text-gray-600 mt-2 mb-6">
                        Our software finder works best on larger screens.
                    </p>
                }
                extra={[
                    <div key="message" className="text-center mb-6">
                        <LaptopOutlined className="text-4xl text-green-500 mb-2"/>
                        <p className="text-gray-700">
                            Visit us from your computer to explore all options. We’re working on mobile access!
                        </p>
                    </div>,
                    <Button
                        key="goBack"
                        type="primary"
                        size="large"
                        className="bg-blue-500 hover:bg-blue-600 border-blue-500 hover:border-blue-600"
                        onClick={() => window.history.back()}
                    >
                        Go Back
                    </Button>
                ]}
            />
        </div>
    );
}

export default UnsupportedDevice;