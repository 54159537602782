import {v4 as uuidv4} from 'uuid';

export function getUserId(): string {
    let userId = localStorage.getItem('userId');
    if (!userId) {
        userId = uuidv4();
        localStorage.setItem('userId', userId);
    }

    return userId;
}

export function sessionId(): string {
    let sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
        sessionId = uuidv4(); // Generate a new UUID
        sessionStorage.setItem('sessionId', sessionId);
    }
    return sessionId;
}
