import {api, ServerAPIResponse} from "../utils/server_utils";

export interface ToolData {
    name: string;
    domain: string;
    logo: string;
    easeOfUse: number;
    pricing: number;
    organizationSize: string;
    love: string;
    hate: string;
    supportLevel: string;
    easeOfIntegration: string;
}


export interface ToolNamesResponse {
    toolNames: string[]
}

export interface FollowupQuestionResponse {
    preQuestionsMsg: string
    topic: string
    question: string
    options: string[]
}

export interface FirstPromptResponse {
    headline: string
    type: ResponseType
    response: ToolNamesResponse | FollowupQuestionResponse
}

export interface MixedResponse {
    toolNamesResponse: ToolNamesResponse
    followupQuestionResponse: FollowupQuestionResponse
}

export enum ResponseType {
    ToolNamesResponse = 'ToolNamesResponse',
    FollowupQuestionResponse = 'FollowupQuestionResponse',
    MixedResponse = 'MixedResponse'
}

export interface NPromptResponse {
    type: ResponseType
    response: ToolNamesResponse | FollowupQuestionResponse | MixedResponse
}

export interface ClientTokensResponse {
    mixpanelToken: string
    hotjarSiteId: number
}

export async function firstPrompt(prompt: string): Promise<FirstPromptResponse> {
    const apiResponse = await api('first_prompt', prompt);
    return apiResponse.response;
}

export async function NPrompt(question: string, option: string): Promise<NPromptResponse> {
    return (await api('n_prompt', question, option)).response;
}

export async function getTools(toolNames: string[]): Promise<ToolData[]> {
    return (await api('get_tools', ...toolNames) as ServerAPIResponse).response.tools
}

export async function fetchClientTokens(): Promise<ClientTokensResponse> {
    return (await api('client_tokens')).response as ClientTokensResponse;
}